import React, { Component } from 'react';
import ReduxProvider from 'ReduxProvider';
import ApiUtils from 'Utils/apiUtil';
import ReactOnRails from 'react-on-rails';
import { Field, reduxForm } from 'redux-form';
import { renderFormField } from 'RenderField/index';
import { formErrorsValidations } from 'Utils/validator';
import { NotificationManager } from 'react-notifications';

const requiredFieldsArray = [
  {
    id: 'first_name',
    validation: [
      {
        rule: 'required',
        message: 'First name is required.',
      },
    ],
  },
  {
    id: 'last_name',
    validation: [
      {
        rule: 'required',
        message: 'Last name is required.',
      },
    ],
  },
  {
    id: 'password',
    validation: [
      {
        rule: 'required',
        message: 'Password is required.',
      },
      {
        rule: 'minlength',
        length: 8,
        message: 'Password must be at least 8 characters in length.',
      },
    ],
  },
  {
    id: 'confirm_password',
    validation: [
      {
        rule: 'required',
        message: 'Confirm password is required.',
      },
      {
        rule: 'matchPassword',
        matchWithField: 'password',
        message: 'Confirm password and new password must match.',
      },
    ],
  },
];

const validate = values => formErrorsValidations(values, requiredFieldsArray);

class ChangePassword extends Component {
  constructor(props, _railsContext) {
    super(props);
    this.state = {
      isSubmitted: false,
    };
  }

  onHandleSubmit = values => {
    const csrfToken = ReactOnRails.authenticityToken();
    let url = window.location.search;
    url = url.split('=').pop();

    const { firstNamePresent, lastNamePresent } = this.props;
    const namesPresent = firstNamePresent + lastNamePresent;

    const form = new FormData();
    if (!namesPresent) {
      form.append('user[first_name]', values.first_name.trim());
      form.append('user[last_name]', values.last_name.trim());
    }
    form.append('user[password]', values.password.trim());
    form.append('user[password_confirmation]', values.confirm_password.trim());
    form.append('user[reset_password_token]', url);
    this.setState({ isSubmitted: true });
    ApiUtils.changePassword(csrfToken, form)
      .then(response => {
        if (parseInt(response.status, 10) === 200) {
          NotificationManager.success('Password Updated Successfully');
          window.location = '/users/sign_in';
          this.setState({ isSubmitted: false });
        }
      })
      .catch(err => {
        if (err.message === 'Request failed with status code 404') {
          NotificationManager.error('Reset password token is invalid');
          this.setState({ isSubmitted: false });
        } else if (err.response.data && err.response.data[0]) {
          NotificationManager.error(err.response.data[0]);
          this.setState({ isSubmitted: false });
        } else {
          NotificationManager.error('Something went wrong!!');
          this.setState({ isSubmitted: false });
        }
      });
  };

  render() {
    const { handleSubmit, firstNamePresent, lastNamePresent } = this.props;
    const { isSubmitted } = this.state;
    const namesPresent = firstNamePresent + lastNamePresent;
    return (
      <div className="changePasswordContainer d-flex align-items-center position-relative font-style">
        <div className="container">
          <div className="row no-gutters">
            <div className="offset-md-2 offset-lg-2 offset-xl-3 u-space-2 u-space-4-top--md">
              {/* <!-- Form --> */}
              <form
                className="js-validate u-space-3-5-bottom--md u-space-2-bottom--sm"
                onSubmit={handleSubmit(this.onHandleSubmit)}
              >
                {/* <!-- Title --> */}
                <div className="mb-2">
                  <h2 className="h3 font-weight-normal mb-0">
                    Change your <span className="font-weight-bold">password</span>
                  </h2>
                </div>
                <div className="mb-7">
                  <p className="mb-0 h9">
                    Need an account? &nbsp;
                    <a href="/users/sign_up">Sign Up</a>
                  </p>
                </div>
                {/* <!-- End Title --> */}

                {!namesPresent && (
                  <div>
                    {/* <!-- Input --> */}
                    <div className="js-form-message mb-4">
                      <label className="h6 small d-block text-uppercase">First Name</label>
                      <div className="row">
                        <div className="col-lg-12">
                          <Field
                            type="text"
                            displayLabel={false}
                            className="changePasswordField form-control u-form__input"
                            name="first_name"
                            data-fieldname="first_name"
                            component={renderFormField}
                            placeholder="Enter your first name"
                          />
                        </div>
                      </div>
                    </div>
                    {/* <!-- End Input --> */}

                    {/* <!-- Input --> */}
                    <div className="js-form-message mb-4">
                      <label className=" h6 small d-block text-uppercase">Last Name</label>
                      <div className="row">
                        <div className="col-lg-12">
                          <Field
                            type="text"
                            displayLabel={false}
                            className="changePasswordField form-control u-form__input"
                            name="last_name"
                            data-fieldname="last_name"
                            component={renderFormField}
                            placeholder="Enter your last name"
                          />
                        </div>
                      </div>
                    </div>
                    {/* <!-- End Input --> */}
                  </div>
                )}

                {/* <!-- Input --> */}
                <div className="js-form-message mb-4">
                  <label className="h6 small d-block text-uppercase">New Password</label>
                  <div className="row">
                    <div className="col-lg-12">
                      <Field
                        type="password"
                        displayLabel={false}
                        className="changePasswordField form-control u-form__input"
                        name="password"
                        data-fieldname="password"
                        component={renderFormField}
                        placeholder="Enter your new password"
                      />
                    </div>
                  </div>
                </div>
                {/* <!-- End Input --> */}

                {/* <!-- Input --> */}
                <div className="js-form-message mb-4">
                  <div className="d-flex justify-content-between align-items-center">
                    <label className="h6 small d-block text-uppercase">Confirm Password</label>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <Field
                        type="password"
                        className="changePasswordField form-control u-form__input"
                        displayLabel={false}
                        name="confirm_password"
                        data-fieldname="password"
                        component={renderFormField}
                        placeholder="Confirm your password"
                      />
                    </div>
                  </div>
                </div>
                {/* <!-- End Input --> */}

                {/* <!-- Button --> */}
                <div className="row align-items-center mb-5">
                  <div className="col-6">
                    <a href="/users/confirmation/new">Didn&apos;t receive confirmation instructions?</a>
                  </div>

                  <div className="col-6 text-right">
                    <button
                      type="submit"
                      disabled={isSubmitted}
                      className="btn btn-primary u-btn-primary transition-3d-hover"
                    >
                      Change my Password
                    </button>
                  </div>
                </div>
                {/* <!-- End Button --> */}
              </form>
              {/* <!-- End Form --> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const ChangePwdComponent = reduxForm({ form: 'change-password', validate })(ChangePassword);

export default (props, _railsContext) => (
  <ReduxProvider {...props}>
    <ChangePwdComponent {...props} />
  </ReduxProvider>
);
